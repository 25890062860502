var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "game-type2", attrs: { id: "game-" + _vm.data.block } },
    [
      _c(
        "v-container",
        { staticClass: "game__vendor" },
        [
          _c(
            "v-row",
            { staticClass: "p-sticky top-0 z-index-5 px-14px" },
            [
              _c(
                "v-col",
                { staticClass: "py-0 px-1 mb-4", attrs: { cols: "12" } },
                [
                  _c(
                    "v-tabs",
                    {
                      staticClass: "game__tab",
                      attrs: {
                        height: "65",
                        "background-color": "bg",
                        color: "primary"
                      },
                      model: {
                        value: _vm.tab,
                        callback: function($$v) {
                          _vm.tab = $$v
                        },
                        expression: "tab"
                      }
                    },
                    _vm._l(_vm.navBars, function(game, index) {
                      return _c(
                        "v-tab",
                        { key: game._id, staticClass: "px-0" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "item d-flex align-center justify-center flex-column w-h-65"
                            },
                            [
                              _vm.navIcon(game)
                                ? _c("img", {
                                    class: [
                                      index === _vm.tab
                                        ? "primary--text"
                                        : "subtitle--text ManufacturerLogo"
                                    ],
                                    attrs: {
                                      src: _vm.baseImgUrl + _vm.navIcon(game),
                                      height: "18"
                                    }
                                  })
                                : _c("Icon", {
                                    class: [
                                      index === _vm.tab
                                        ? "primary--text"
                                        : "subtitle--text"
                                    ],
                                    attrs: {
                                      data: _vm.icons[game.class],
                                      width: "18",
                                      height: "18"
                                    }
                                  }),
                              _c(
                                "span",
                                {
                                  staticClass: "caption mt-1",
                                  class: [
                                    index === _vm.tab
                                      ? "primary--text"
                                      : "subtitle--text"
                                  ]
                                },
                                [_vm._v(_vm._s(game.game_type))]
                              )
                            ],
                            1
                          )
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.activeTabType === "vendor" &&
          _vm.activeTabOpenMethod === "list_page"
            ? _c(
                "v-row",
                { staticClass: "overlay-auto game-content px-14px" },
                [
                  _vm._l(_vm.activeNavbarContent[0].games, function(games) {
                    return _c(
                      "v-col",
                      {
                        key: games._id,
                        staticClass: "px-1 pt-0 pb-10 pb-0",
                        attrs: { cols: 6 }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "game-content rounded h-125 cursor-pointer",
                            style: _vm.gradientBg
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "rounded overflow-hidden",
                                class:
                                  _vm.item.status === "0"
                                    ? "maintenanceBox"
                                    : ""
                              },
                              [
                                _vm.item.status == "0"
                                  ? _c("maintenance")
                                  : _vm._e(),
                                _c("v-img", {
                                  staticClass: "rounded",
                                  attrs: {
                                    src: _vm.baseImgUrl + games.game.image,
                                    height: "125",
                                    position: "center right",
                                    alt: games.localized_name
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.lauchGame(
                                        games.game_code,
                                        games.status
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "game-content__title subtitle-2 icon--text"
                              },
                              [_vm._v(_vm._s(games.localized_name))]
                            )
                          ]
                        )
                      ]
                    )
                  }),
                  _c(
                    "v-col",
                    {
                      staticClass: "px-1 pt-0 pb-10 pb-0 moreBtn",
                      attrs: {
                        cols:
                          _vm.activeNavbarContent.length > 9 ||
                          _vm.activeVendorType.isOdd
                            ? 6
                            : 12
                      },
                      on: {
                        click: function($event) {
                          return _vm.lauchGame(
                            _vm.activeNavbarContent.game_code,
                            _vm.activeNavbarContent[0].hyperlink
                          )
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "game-content rounded h-125 cursor-pointer d-flex justify-center align-center flex-column",
                          style: _vm.gradientBg
                        },
                        [
                          _c("Icon", {
                            attrs: {
                              data: _vm.moreIcon,
                              width: "18",
                              height: "18"
                            }
                          }),
                          _vm._v(" More ")
                        ],
                        1
                      )
                    ]
                  )
                ],
                2
              )
            : _c(
                "v-row",
                { staticClass: "overlay-auto game-content px-14px" },
                [
                  _vm._l(_vm.activeNavbarContent.slice(0, 9), function(
                    item,
                    index
                  ) {
                    return _c(
                      "v-col",
                      {
                        key: item._id,
                        staticClass: "px-1 pt-0 pb-10 pb-0",
                        attrs: {
                          cols: _vm.activeNavbarContent.length > 1 ? 6 : 12
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "game-content rounded h-125 cursor-pointer",
                            style: _vm.gradientBg
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "rounded overflow-hidden",
                                class:
                                  item.status === "0" ? "maintenanceBox" : ""
                              },
                              [
                                item.status == "0"
                                  ? _c("maintenance")
                                  : _vm._e(),
                                _c("v-img", {
                                  staticClass: "rounded",
                                  attrs: {
                                    src:
                                      _vm.activeVendorType.isOdd &&
                                      _vm.activeVendorType
                                        .stretchVendorIndex === index &&
                                      item.image_h5_2
                                        ? _vm.baseImgUrl + item.image_h5_2
                                        : _vm.baseImgUrl + item.image_h5,
                                    height: "125",
                                    position: "center right"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.lauchGame(
                                        item.game_code,
                                        item.hyperlink
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "game-content__title subtitle-2 icon--text"
                              },
                              [_vm._v(_vm._s(item.title))]
                            )
                          ]
                        )
                      ]
                    )
                  }),
                  _vm.activeNavbarContent.length > 1
                    ? _c(
                        "v-col",
                        {
                          staticClass: "px-1 pt-0 pb-10 pb-0 moreBtn",
                          attrs: {
                            cols:
                              _vm.activeNavbarContent.length > 9 ||
                              _vm.activeVendorType.isOdd
                                ? 6
                                : 12
                          },
                          on: {
                            click: function($event) {
                              return _vm.goVendorPage(_vm.activeVendorPageLink)
                            }
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "game-content rounded h-125 cursor-pointer d-flex justify-center align-center ",
                              class:
                                _vm.activeNavbarContent.length > 9 ||
                                _vm.activeVendorType.isOdd
                                  ? "flex-column"
                                  : "flexRow",
                              style: _vm.gradientBg
                            },
                            [
                              _c("Icon", {
                                attrs: {
                                  data: _vm.moreIcon,
                                  width: "18",
                                  height: "18"
                                }
                              }),
                              _vm._v(" More ")
                            ],
                            1
                          )
                        ]
                      )
                    : _vm._e()
                ],
                2
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }